import ExperienceItem from "./ExperienceItem"

const experienceData = [
  {
    title: "Engineering Manager",
    company: "The Make Cents Foundation (Develop For Good)",
    period: "October 2024 - Present",
    responsibilities: [
      "Led the development for a full-stack mobile application using React Native, Firebase Authentication, Cloud Functions, and Firestore to improve student financial literacy",
      "Managed team of engineers through Agile methodologies, utilizing Trello and GitHub for task tracking, leading weekly team meetings, conducting code review & managing pull requests"
    ]
  },
  {
    title: "Software Engineering Intern",
    company: "Stony Brook Administrative Services, LLC",
    period: "September 2024 - December 2024",
    responsibilities: [
      "Designed a PostgreSQL database and data migration pipeline using Spark and Node.js to migrate patient records, improving query performance & integration with healthcare applications",
      "Developed a Python validation system comparing PDF medical records against C-CDA data, ensuring 100% data accuracy across patient records while reducing data verification time"
    ]
  },
  {
    title: "Machine Learning Researcher",
    company: "Massive Data Institute @ Georgetown University",
    period: "June 2023 - December 2023",
    responsibilities: [
      "Applied ML and NLP to extract and analyze emotional signals within Twitter/X data to better improve forced migration forcasting methods",
      "Fine-tuned a pretrained LLM on a dataset of 50,000+ labeled tweets that was able to predict target emotions with over 80% accuracy",
      "Presented research to the United Nations Refugee Agency and academic faculty at Georgetown University, gaining valuable experience in communicating complex data-driven insights"
    ]
  },
  {
    title: "Software Engineering Intern",
    company: "Applause",
    period: "May 2022 - August 2023",
    responsibilities: [
      "Refined and tested algorithms essential for the development of the next generation of virtual reality (VR) devices",
      "Implemented automated anomaly detection to identify data samples that failed to meet development and testing standards",
      "Designed and deployed a robust ETL process to help to improve data collection methodologies for future iterations"
    ]
  },
  {
    title: "Engineering Teacher Assistant",
    company: "The Cooper Union",
    period: "October 2021 - March 2022",
    responsibilities: [
      "Enabled students to develop familiarity with extended reality and aided students in developing skills in engineering design, computer-aided design, and web-based programming",
      "Assisted student teams in developing multiple projects, digital portfolios, and final presentations involving using CAD, HTML, and JavaScript",
      "Led several lessons during the program, planned lesson materials, and conducted one-on-one mentoring to support student learning and comprehension"
    ]
  }
]

export default function Experience() {
  return (
    <section id="experience" className="mb-20">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-gray-900 dark:text-white">Experience</h2>
      <div className="space-y-12">
        {experienceData.map((item, index) => (
          <ExperienceItem key={index} {...item} />
        ))}
      </div>
    </section>
  )
}